import { Component } from '@angular/core';  
 import { Platform } from '@ionic/angular';  
 import { SplashScreen } from '@ionic-native/splash-screen/ngx';  
 import { StatusBar } from '@ionic-native/status-bar/ngx'; 
@Component({  
   selector: 'app-root',  
   templateUrl: 'app.component.html',  
   styleUrls: ['app.component.scss']  
 })  
 export class AppComponent {  
   navigate: any;  
   constructor(   
     private platform: Platform,  
     private splashScreen: SplashScreen,  
     private statusBar: StatusBar  
   ) {   
     this.sideMenu();  
     this.initializeApp();  
   }  
   initializeApp() {  
     this.platform.ready().then(() => {  
       this.statusBar.styleDefault();   
       this.splashScreen.hide();  
     });  
   }  
   sideMenu() {  
     this.navigate =   
     [  
         { 
         title : 'Keuringsrapportage',
         url   : '/tabs/tab1',
         icon  : 'albums' 
         },
       { 
         title : 'Beoordelings formulier tractor',  
         url   : '/tabs/tab2',  
         icon  : 'albums'  
       },   
       {  
         title : 'Beoordelings formulier heftruck',  
         url   : '/tabs/tab9',  
         icon  : 'albums'   
       },  
       {  
         title : 'Beoordelings formulier hoogwerker',  
         url   : '/tabs/tab4',  
         icon  : 'albums'  
       },   
      //  {
      //      title : 'Beoordelings formulier heftruck',
      //      url   : '/tabs/tab5',
      //      icon  : 'albums'
      //  },
       {
        title : 'Beoordelings formulier grondverzetmachine',
        url   : '/tabs/tab6',
        icon  : 'albums'
    },
    {
      title : 'Beoordelings formulier shovel',
      url   : '/tabs/tab7',
      icon  : 'albums'
  },
  {
    title : 'PDF maken',
    url   : '/tabs/tab8',
    icon  : 'add'
},
{
  title : 'Profiel',
  url   : '/tabs/tab3',
  icon  : 'person'
},
     ];  
   }  
 }   