import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
const redirectLoggedInToApp = () => redirectLoggedInTo(['/tabs']);
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
const routes: Routes = [
  { path: '', redirectTo: 'login',pathMatch: 'full'},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [IntroGuard, AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToApp } },
  { path: 'introduction', loadChildren: () => import('./pages/introduction/introduction.module').then(m => m.IntroductionPageModule) },
  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [IntroGuard, AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },

  
 

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
